import "@css/app.css";

/* JS */
import "lazysizes";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

Fancybox.bind("[data-fancybox]");

window.disableBodyScroll = disableBodyScroll;
window.enableBodyScroll = enableBodyScroll;

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();

const appHeight = () => {
	const doc = document.documentElement;
	doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};
window.addEventListener("resize", appHeight);
appHeight();

window.appHeight = appHeight;

const header = document.querySelector(".site-header");

const headerHeight = () => {
	const doc = document.documentElement;
	doc.style.setProperty("--header-height", `${header.offsetHeight}px`);
	console.log(header.offsetHeight);
};
window.addEventListener("resize", headerHeight);
headerHeight();

window.headerHeight = headerHeight;

var scrollLockEnabled = false;
let theNav = document.querySelector(".mobile-nav");
function shouldScrollLock() {
	if (!scrollLockEnabled) {
		disableBodyScroll(theNav);
	} else {
		enableBodyScroll(theNav);
	}
	scrollLockEnabled = !scrollLockEnabled;
}

window.shouldScrollLock = shouldScrollLock;

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import Swiper from "swiper";
import { Pagination, EffectFade, Autoplay } from "swiper/modules";

Array.from(document.querySelectorAll(".js-slider-banner")).forEach(
	(container) => {
		const slider = container.querySelector(".swiper");
		const pagination = container.querySelector(".swiper-pagination");

		const swiper = new Swiper(slider, {
			modules: [Pagination, EffectFade, Autoplay],
			loop: true,
			autoHeight: false,
			speed: 1000,
			effect: "fade",
			fadeEffect: {
				crossFade: true,
			},
			pagination: {
				el: pagination,
				clickable: true,
			},
			autoplay: {
				delay: 8000,
			},
		});
	}
);

import $ from "jquery";
import "bootstrap/js/dist/modal";

function init() {
	/* speedbump */
	$.expr[":"].external = function (a) {
		var linkhn = a.hostname.split(".").reverse();
		var linkHref = linkhn[1] + "." + linkhn[0];
		var domainhn = window.location.hostname.split(".").reverse();
		var domainHref = domainhn[1] + "." + domainhn[0];
		return (
			!a.href.match(/^mailto\:/) &&
			!a.href.match(/^tel\:/) &&
			linkHref !== domainHref &&
			a.href.match(/^http/)
		);
	};
	$("a:external").addClass("ext_link");
	$(function () {
		$("a.ext_link:not(.not_ext)").click(function () {
			// open a modal
			$("#leavingSiteModal").modal();
			$("a:external").attr("data-toggle", "modal");
			$("a:external").attr("data-target", "#speedbump");
			//go to link on modal close
			var url = $(this).attr("href");
			$(".btn-modal.btn-continue").click(function () {
				window.open(url);
				$(".btn-modal.btn-continue").off();
				$("#leavingSiteModal").modal("hide");
			});
			$(".btn-modal.btn-close").click(function () {
				$("#leavingSiteModal").modal("hide");
				$(".btn-modal.btn-close").off();
			});
		});
	});
}

document.addEventListener("DOMContentLoaded", () => {
	init();
});
